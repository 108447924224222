export default {
  settings: {
    languageId: 'en',
  },
  labels: {
    en: {
      'limitedAccess.banner.description':
        'Ready for deeper insights? Activate data distribution capabilities today and dive into a wealth of valuable information.',
      'limitedAccess.alert.description':
        'Delivery of your data feeds is limited to # rows. Activate data distribution to unlock full delivery and distribution capabilities of all your feeds.',
      'limitedAccess.button': 'Activate Data Distribution',
    },
  },
}
